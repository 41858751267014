import { Button } from "@nextui-org/react";
import { MetaFunction } from "@remix-run/cloudflare";
import { useSearchParams } from "@remix-run/react";
import { useCallback, useEffect, useMemo } from "react";
import { HiSparkles } from "react-icons/hi2";

import Followup from "./followup";
import Logo from "~/components/logo";

import Navbar from "~/components/navbar";
import Provider from "~/components/provider";
import { useSearch } from "~/stores/search";

import { useUser } from "~/stores/user";

export const meta: MetaFunction = ({ location }) => {
  const q = new URLSearchParams(location.search).get("q");

  return [
    { title: `${q} - Search` },
    {
      name: "robots",
      content: "noindex, follow",
    },
    {
      name: "googlebot",
      content: "noindex, follow",
    },
    {
      name: "description",
      content:
        "Discover India's top service providers on Tribe! From photographers to interior designers and fitness trainers, find the perfect match for your needs today.",
    },
  ];
};

export default function SearchPage() {
  const [searchParams] = useSearchParams();
  const { location } = useUser();
  const { search, results, loading, hasMore } = useSearch();

  const q = searchParams.get("q");

  const highConfidenceResults = useMemo(() => {
    return results.filter(
      (provider) =>
        (provider.confidence === undefined || provider.confidence === "high") &&
        (provider.images === undefined || provider.images.length > 0)
    );
  }, [results]);

  useEffect(() => {
    const init = async () => {
      if (!location) {
        // Wait for location to load
        return;
      }

      if (!q) {
        // TODO: redirect to home
        return;
      }

      search(q, 0, location);
    };

    init();
  }, [location, q, search]);

  const loadMore = useCallback(() => {
    if (hasMore && q && location) {
      search(q, results.length, location);
    }
  }, [hasMore, q, location, search, results.length]);

  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center">
        <div className="w-full max-w-7xl">
          <div className="min-h-[calc(100dvh-64px)] py-6 flex flex-col px-4 sm:px-6 gap-8">
            <div className="flex space-x-2 items-center">
              <HiSparkles className="w-6 h-6 text-primary" />
              <h2 className="text-2xl font-medium">{q}</h2>
            </div>

            {loading && results.length === 0 && (
              <div className="-mx-4 -sm:mx-6 mb-8 px-4 max-w-screen overflow-hidden flex-1 flex flex-col justify-center items-center relative">
                <Logo className="h-40 w-40 md:h-64 md:w-64" animating={true} />
              </div>
            )}

            {!loading && highConfidenceResults.length === 0 && (
              <div className="flex-1 flex flex-col justify-center items-center py-10 gap-8">
                <p className="text-4xl font-bold text-default-500 text-center">
                  Oops!
                </p>
                <p className="text-center mt-2">
                  No results found for &quot;{q}&quot;.
                </p>
              </div>
            )}

            <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {highConfidenceResults.map((provider) => {
                return (
                  <div key={provider.slug}>
                    <Provider
                      key={provider.slug}
                      {...provider}
                      q={q}
                      differentiator=""
                      images={provider.images || []}
                      confidence={provider.confidence}
                    />
                  </div>
                );
              })}
            </div>

            {highConfidenceResults.length > 0 && (
              <div className="flex space-x-2 items-center justify-center">
                {hasMore ? (
                  <Button isLoading={loading} variant="flat" onPress={loadMore}>
                    Load More
                  </Button>
                ) : (
                  <p className="text-sm text-center text-default-500">
                    No more results.
                  </p>
                )}
              </div>
            )}
          </div>
          <Followup />
        </div>
      </div>
    </>
  );
}
